<template>
  <v-row>
    <v-col cols="12" sm="12" md="3">
      <listagem-terminal
        nome="Pátios"
        placeholder="Novo Pátio..."
        :items="patios"
        tipo="patio"
        @next="next"
        @deleteItem="deleteItem"
        @save="criarPatio"
        @edit="editarPatio"
        :hasSelecionado="true"
        :hasNext="true"
        :hasEdit="false"
      />
    </v-col>

    <v-col cols="12" sm="12" md="3">
      <listagem-terminal
        nome="Terminais"
        placeholder="Novo terminal..."
        :items="terminaisA"
        tipo="terminal"
        @next="next"
        @deleteItem="deleteItem"
        @save="criarTerminal"
        @edit="editarTerminal"
        :hasSelecionado="patioSelecionado.selecionado"
        :hasNext="true"
        :hasEdit="true"
      />
    </v-col>

    <v-col cols="12" sm="12" md="3">
      <listagem-terminal
        nome="Produtos"
        placeholder="Novo produto..."
        :items="produtosA"
        tipo="produto"
        @next="next"
        @deleteItem="deleteItem"
        @save="criarProduto"
        @edit="editarProduto"
        :hasSelecionado="terminalSelecionado.selecionado"
        :hasNext="true"
        :hasEdit="true"
      />
    </v-col>

    <v-col cols="12" sm="12" md="3">
      <listagem-terminal
        nome="Subprodutos"
        placeholder="Novo subproduto..."
        :items="subProdutosA"
        tipo="subproduto"
        @deleteItem="deleteItem"
        @save="criarSubProduto"
        @edit="editarSubProduto"
        :hasSelecionado="produtoSelecionado.selecionado"
        :hasNext="false"
        :hasEdit="true"
      />
    </v-col>

    <v-dialog
      v-model="hasErrorDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <v-card-text >
            <h1 class="transition-swing text-h1 mb--1 d-flex justify-center w-100 error__delete_title">
              OPS!
            </h1>
            <p class="font-weight-medium d-flex justify-center mt-10 error__delete_text">
              Ocorreu um erro no processamento!
            </p>
            <p class="font-weight-medium d-flex justify-center error__delete_text">
              Verifique se não há registros vinculados a este {{errorName}}!
            </p>
          </v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            text
            @click="hasErrorDelete = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogEdit"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <v-card-text >
            <h3 class="transition-swing text-h3 mb--1">
              Editar
            </h3>
            
          </v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            text
            @click="dialogEdit = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-row>
</template>

<style lang="scss">
  .error__delete_title {
    color: #ff5252;
  }
  .error__delete_text {
    color: #545454;
  }
</style>

<script>
import { mapState, mapActions } from 'vuex'

import ListagemTerminal from './ListagemTerminal'

export default {
  name: 'CardsTerminal',
  components: {
    ListagemTerminal
  },

  data () {
    return {
      patioSelecionado: {
        data: {},
        selecionado: false
      },
      terminalSelecionado: {
        data: {},
        selecionado: false
      },
      produtoSelecionado: {
        data: {},
        selecionado: false
      },
      subProdutoSelecionado: {
        data: {},
        selecionado: false
      },
      selecionadoDefault: {
        data: {},
        selecionado: false
      },
      ultimoPatioCriado: {},
      ultimoTerminalCriado: {},
      ultimoProdutoCriado: {},
      ultimoSubProdutoCriado: {},
      hasErrorDelete: false,
      errorName: 'pátio',
      dialogEdit: false
    }
  },

  computed: {
    ...mapState('patio', ['patios', 'terminais', 'produtos']),
    ...mapState('subProdutos', ['subProdutos']),
    ...mapState('auth', ['empresaAtual']),

    terminaisA () {
      if (this.patioSelecionado.selecionado) {
        const terminal = this.terminais.filter(terminal => terminal.patio === this.patioSelecionado.data.id)
        return terminal
      } else {
        return []
      }
    },

    produtosA () {
      if (this.terminalSelecionado.selecionado) {
        const produto = this.produtos.filter(produto => produto.terminal === this.terminalSelecionado.data.id)
        return produto
      } else {
        return []
      }
    },

    subProdutosA () {
      if (this.produtoSelecionado.selecionado) {
        const subProduto = this.subProdutos.filter(subProduto => subProduto.produto === this.produtoSelecionado.data.id)
        return subProduto
      } else {
        return []
      }
    }
  },

  created() {
    this.getPatio()
    this.getTerminal()
    this.getProduto()
    this.getSubProduto()

  },

  methods: {
    ...mapActions('patio', [
        'getPatio', 'getTerminal', 'getProduto',
        'addPatio', 'addTerminal', 'addProduto', 'addSubProduto',
        'deletePatio', 'deleteTerminal', 'deleteProduto', 'deleteSubProduto'
      ]),
    ...mapActions('subProdutos', ['getSubProduto']),

    next(item, tipo) {
      if (tipo === "patio") {
        if (this.patioSelecionado.data.id !== item.id) {
          Object.assign(this.terminalSelecionado, this.selecionadoDefault)
          Object.assign(this.produtoSelecionado, this.selecionadoDefault)
          Object.assign(this.subProdutosSelecionado, this.selecionadoDefault)
        }
        this.patioSelecionado.selecionado = true
        this.patioSelecionado.data = item
      }
      if (tipo === "terminal") {
        if (this.terminalSelecionado.data.id !== item.id) {
          Object.assign(this.produtoSelecionado, this.selecionadoDefault)
          Object.assign(this.subProdutosSelecionado, this.selecionadoDefault)
        }
        this.terminalSelecionado.selecionado = true
        this.terminalSelecionado.data = item
      }
      if (tipo === "produto") {
        if (this.produtoSelecionado.data.id !== item.id) {
          Object.assign(this.subProdutosSelecionado, this.selecionadoDefault)
        }
        this.produtoSelecionado.selecionado = true
        this.produtoSelecionado.data = item
      }
    },

    async deleteItem(id, tipo) {
      let result
      switch (tipo) {
        case 'patio':
          result = await this.deletePatio(id)
          break;
        case 'terminal':
          result = await this.deleteTerminal(id)
          break;
        case 'produto':
          result = await this.deleteProduto(id)
          break;
        case 'subproduto':
          result = await this.deleteSubProduto(id)
          break;
      }

      if (!result) {
        this.errorName = tipo === 'patio' ? 'pátio' : tipo
        this.hasErrorDelete = true
      }
    },

    async criarPatio (data) {
      data.proprietario = this.empresaAtual.id
      await this.addPatio(data)
      await this.getPatio(this.empresaAtual.id)
    },

    async criarTerminal (data) {
      data.proprietario = this.empresaAtual.id
      data.patio = this.patioSelecionado.data.id
      await this.addTerminal(data)
      await this.getTerminal(this.empresaAtual.id)
    },

    async criarProduto (data) {
      data.proprietario = this.empresaAtual.id
      data.terminal = this.terminalSelecionado.data.id
      await this.addProduto(data)
      await this.getProduto(this.empresaAtual.id)
    },

    async criarSubProduto (data) {
      data.proprietario = this.empresaAtual.id
      data.produto = this.produtoSelecionado.data.id
      await this.addSubProduto(data)
      await this.getSubProduto(this.empresaAtual.id)
    },

    editarPatio (item) {
      console.log(item)
      this.dialogEdit = true
    },

    editarTerminal (item) {
      console.log(item)
      this.dialogEdit = true
    },

    editarProduto (item) {
      console.log(item)
      this.dialogEdit = true
    },

    editarSubProduto (item) {
      console.log(item)
      this.dialogEdit = true
    },

  }
}
</script>